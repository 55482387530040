import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const Container = styled.section`
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  margin-top: 20px;

  .item-sizes {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;

    > div {
      padding: 2px;
      margin: 0 1px 4px;
      width: 55px;
      height: 40px;
      font-size: 0.875rem;
      position: relative;
    }

    > div:nth-child(1) {
      background: #008941;
    }

    > div:nth-child(2) {
      background: #ededed;
      color: rgb(42, 44, 43);
    }

    > div:nth-child(3) {
      background: #d93442;
    }
  }

  .custom-shape-divider-bottom-1605973662 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1605973662 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 16px;
  }

  .custom-shape-divider-bottom-1605973662 .shape-fill {
    fill: rgb(42, 44, 43);
  }
`;

const PriceContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const FixedPrice = styled.div`
  p {
    margin-top: 4px;
    padding: 5px;
    font-size: 0.875rem;
  }

  .gatsby-image-wrapper {
    max-width: ${props => props.width};
    margin: 0 auto;
  }
`;

const PizzaSizes = () => (
  <StaticQuery
    query={graphql`
      query {
        pizzap: file(relativePath: { eq: "pizza-slice.png" }) {
          childImageSharp {
            fluid(maxWidth: 128, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pizzag: file(relativePath: { eq: "pizza-slice.png" }) {
          childImageSharp {
            fluid(maxWidth: 128, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pizzagg: file(relativePath: { eq: "pizza-slice.png" }) {
          childImageSharp {
            fluid(maxWidth: 128, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Container>
        <div className="item-sizes">
          <div>
            PEQ
            <div className="custom-shape-divider-bottom-1605973662">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M1200 0L0 0 598.97 114.72 1200 0z"
                  className="shape-fill"
                ></path>
              </svg>
            </div>
          </div>

          <div>
            GRA
            <div className="custom-shape-divider-bottom-1605973662">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M1200 0L0 0 598.97 114.72 1200 0z"
                  className="shape-fill"
                ></path>
              </svg>
            </div>
          </div>

          <div>
            FAM
            <div className="custom-shape-divider-bottom-1605973662">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M1200 0L0 0 598.97 114.72 1200 0z"
                  className="shape-fill"
                ></path>
              </svg>
            </div>
          </div>
        </div>

        <PriceContainer>
          <FixedPrice width="80%">
            <Img fluid={data.pizzap.childImageSharp.fluid} />
            <p>4 Fatias</p>
          </FixedPrice>

          <FixedPrice width="90%">
            <Img fluid={data.pizzag.childImageSharp.fluid} />
            <p>8 Fatias</p>
          </FixedPrice>

          <FixedPrice width="100%">
            <Img fluid={data.pizzagg.childImageSharp.fluid} />
            <p>12 Fatias</p>
          </FixedPrice>
        </PriceContainer>
      </Container>
    )}
  />
);

export default PizzaSizes;
